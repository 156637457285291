/**
 * @file   src\containers\ForgotPassword.tsx
 * @brief  Forgot Password Page.
 * @date   Sep, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */
import { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { ForgotPasswordRequest } from '../interfaces/authInterface';
import { useAppDispatch, useAppSelector } from '../hooks/index';
import { forgotPassword } from '../store/actions/authAction';
import { resetForgotPasswordState } from '../store/slices/authSlice';
import '../assets/styles/prelogin.scss';
import Logo from '../assets/img/logo-prelogin.svg';
import ForgotPswd from '../components/ForgotPass';
import ForgotPassIc from '../assets/img/icons/ForgotPass';
import ForgotMailIc from '../assets/img/icons/ForgotMailSent';
import ForgotMail from '../components/ForgotMail';
import * as CONSTANT from '../utils/constants';
import * as ALERT from '../utils/alerts';

const ForgotPassowrd = () => {
  const dispatch = useAppDispatch();
  const [forgotEmail, setForgotEmail] = useState<string>('');
  const [forgotEmailError, setForgotEmailError] = useState<string>('');
  const [mailSentSucess, setMailSentSucess] = useState<boolean>(false);

  const forgorPassword = (forgotMail: ForgotPasswordRequest) => dispatch(forgotPassword(forgotMail));
  const forgotResponseData = useAppSelector((RootReducer) => RootReducer.auth.ForgotPassword);

  // handles forgot password api response
  useEffect(() => {
    if (forgotResponseData.isLoading === false && forgotResponseData.isSuccess === true) {
      if (forgotResponseData.errorCode === CONSTANT.API_ERRCODE && forgotResponseData.errorMessage) {
        toast.error(forgotResponseData.errorMessage);
      } else if (forgotResponseData.errorCode === CONSTANT.API_SUCCESSCODE) {
        if (mailSentSucess) {
          toast.success(forgotResponseData?.errorMessage);
        } else {
          setMailSentSucess(!mailSentSucess);
        }
      }
    }
    dispatch(resetForgotPasswordState());
  }, [forgotResponseData]);

  const handleEmailChanges = (event: { target: { name: any; value: string } }) => {
    setForgotEmail(event.target.value);
  };

  // handles email validation
  const validateEmail = (email: string) => {
    let errors = '';
    const reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    if (!email) {
      errors = 'Enter Email Address.';
    } else if (!reg.test(email)) {
      errors = ALERT.ENTER_VALID_EMAIL;
    }
    return errors;
  };

  // handles form submit
  const handleSubmit = () => {
    const validate = validateEmail(forgotEmail);
    setForgotEmailError(validate);
    if (validate === '') {
      forgorPassword({ email: forgotEmail, is_web: 1 });
    }
  };

  return (
    <Container fluid className="pre-login">
      <Row>
        <Col lg="6">
          <div className="nb-caption d-flex align-items-center justify-content-center">{!mailSentSucess ? <ForgotPassIc /> : <ForgotMailIc />}</div>
        </Col>
        <Col lg="6" className="d-flex align-items-center justify-content-center">
          <div className="nb-login">
            <img src={Logo} alt="" className="mb-3" />
            {!mailSentSucess ?
              <ForgotPswd handleEmailChanges={handleEmailChanges} email={forgotEmail} emailError={forgotEmailError} handleSubmit={handleSubmit} /> :
              <ForgotMail handleSubmit={handleSubmit} />}
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default ForgotPassowrd;
